import React from "react";
import ProfileCard from "../ProfileCard";
import { Box } from "@mui/system";
import { Employee } from "../../../core/models/employee";

interface CardUserMentorProps {
  profile: Employee;
}

export const ProfileCardUserMentor = ({ profile }: CardUserMentorProps) => {
  return (
    <>
      {profile?.mentors?.length ? (
        <Box>
          {profile?.mentors.map((mentor, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "16px 20px",
                borderTop: "1px solid #E0E0E0",
              }}
            >
              <ProfileCard profile={mentor} isMentorProfileCard={true} />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px 20px",
            borderTop: "1px solid #E0E0E0",
            color: "text.secondary",
            fontSize: "12px",
          }}
        >
          You don't have mentor's
        </Box>
      )}
    </>
  );
};
