import {HttpService} from "./httpService";
import {Register, RegisterStatus} from "../models/register";

class RegisterService {
    constructor(private httpService: HttpService<Register | RegisterStatus>) {
    }

    setToken(token: string) {
        this.httpService.setToken(token);
    }

    async register(body: any) {
        return await this.httpService.post(body, "/employee/register");
    }
}

const httpService = new HttpService<Register | RegisterStatus>();
const singleton = new RegisterService(httpService);
export default singleton;
