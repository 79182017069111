import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import EmployeeService from "../core/services/employeeService";
import { Employee, Mentee, Mentor } from "../core/models/employee";
import { UserContext } from "../App";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarComponent } from "./snackbar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 520,
  backgroundColor: "background.paper",
  borderRadius: "20px",
  padding: "20px",
};

interface ModalProps {
  user?: Employee;
  userToEndRelation?: Mentor | Mentee | Employee;
  isModalOpen: boolean;
  setCloseModal: () => void;
  requestSent: () => void;
  isEndModal?: boolean;
}

const RequestModal = ({
  user,
  isModalOpen,
  setCloseModal,
  requestSent,
  userToEndRelation,
  isEndModal = false,
}: ModalProps) => {
  let navigate = useNavigate();
  let { profileId } = useParams<"profileId">();

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [isSnackBarErrorOpen, setIsSnackBarErrorOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let { reloadUser } = React.useContext(UserContext);

  let requestMentoringButton: JSX.Element | null = null;
  if (user?.links?.mentoring_request?.href) {
    requestMentoringButton = (
      <Button
        variant="contained"
        sx={{
          height: "45px",
          width: "200px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
        }}
        onClick={async () =>
          await sendRequest(
            message,
            user?.links?.mentoring_request?.href,
            user?.links?.mentoring_request?.type
          )
        }
      >
        Send request
      </Button>
    );
  }

  let mentoringEndButton: JSX.Element | null = null;
  if (userToEndRelation?.links?.mentoring_end?.href) {
    mentoringEndButton = (
      <Button
        variant="outlined"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          marginLeft: "10px",
        }}
        onClick={async () => {
          await sendRequest(
            message,
            userToEndRelation?.links?.mentoring_end?.href,
            userToEndRelation?.links?.mentoring_end?.type
          );
        }}
      >
        End mentorship
      </Button>
    );
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
    setIsSnackBarErrorOpen(false);
    setSnackBarMessage("");
  };

  const sendRequest = async (body: string, url: string, method: string) => {
    setLoading(true);
    try {
      await EmployeeService.requestMentoring({ message }, url, method);
      setSnackBarMessage(
        isEndModal
          ? `You've ended the mentoring of ${userToEndRelation?.firstName} ${userToEndRelation?.lastName}`
          : `You've send a request to ${user?.firstName} ${user?.lastName} 🎉`
      );
      setIsSnackBarOpen(true);
      setCloseModal();
      requestSent();
      setLoading(false);
      await reloadUser();
      if (isEndModal) {
        navigate(profileId ? `/profile/${userToEndRelation?.id}` : "/profile");
      }
    } catch (e) {
      setIsSnackBarErrorOpen(true);
      setCloseModal();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={setCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h5"
            sx={{
              color: "text.primary",
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            {isEndModal ? (
              <Box>
                End your relationship with {userToEndRelation?.firstName}{" "}
                {userToEndRelation?.lastName}
              </Box>
            ) : (
              <Box>
                Request {user?.firstName} {user?.lastName} to be your mentor via
                Slack
              </Box>
            )}
          </Typography>
          <Box sx={{ padding: "40px 0" }}>
            <Typography>Your message</Typography>
            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="You can join a personal message"
              style={{
                width: "100%",
                height: "250px",
                borderRadius: "8px",
                padding: "10px",
              }}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box>
                  {isEndModal ? (
                    <Box>{mentoringEndButton}</Box>
                  ) : (
                    <Box>{requestMentoringButton}</Box>
                  )}
                </Box>
              )}
            </Box>
            <Button
              variant="text"
              sx={{
                height: "30px",
                borderRadius: "54px",
                textDecoration: "underline",
                color: "text.secondary",
              }}
              onClick={setCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <SnackbarComponent
        type={"error"}
        isSnackBarOpen={isSnackBarErrorOpen}
        handleClose={handleClose}
        message={"Something went wrong"}
      />
      <SnackbarComponent
        type={"success"}
        isSnackBarOpen={isSnackBarOpen}
        handleClose={handleClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default RequestModal;
