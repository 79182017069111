import { Box, Typography } from "@mui/material";

interface StepTitleProps {
  title: string;
  subtitle: string;
}

const StepTitle = ({ title, subtitle }: StepTitleProps) => {
  return (
    <Box>
      <Typography
        sx={{ color: "text.primary", fontSize: "32px", margin: "10px 0" }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "24px",
          color: "text.secondary",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default StepTitle;
