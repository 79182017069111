import { Box, Checkbox, Chip, Typography, useTheme } from "@mui/material";

interface SpecialBoxProps {
  title: string;
  subtitle: string;
  content: string;
  isSelected: boolean;
  onClick?: any;
  isComingSoon?: boolean;
  isDisabled?: boolean;
}

const SpecialCard = ({
  title,
  subtitle,
  content,
  isSelected,
  onClick,
  isComingSoon,
  isDisabled,
}: SpecialBoxProps) => {
  const theme = useTheme();

  const borderIsDisabled = isDisabled
    ? `2px solid rgba(0, 0, 0, 0.12)`
    : "2px solid #B7B7B7";

  const backgroundColorIsDisabled = isDisabled ? "#edecec" : "";

  const colorIsDisabled = !isDisabled ? "text.primary" : "text.secondary";

  return (
    <Box
      sx={{
        width: "100%",
        height: "300px",
        border: isSelected
          ? `2px solid ${theme.palette.primary.main}`
          : borderIsDisabled,
        borderRadius: "30px",
        color: isSelected ? "#ffffff" : "text.primary",
        cursor: !isDisabled ? "pointer" : "",
        zIndex: 100,
        position: "relative",
        padding: "5px",
        backgroundColor: isSelected
          ? theme.palette.primary.main
          : backgroundColorIsDisabled,
      }}
      onClick={onClick}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "#ffffff",
            },
          }}
          disabled={isDisabled}
          checked={isSelected}
        />
      </Box>
      <Box sx={{ padding: "0 32px" }}>
        <Typography
          sx={{
            color: isSelected ? "#ffffff" : colorIsDisabled,
            fontWeight: "500",
            fontSize: "30px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: !isDisabled ? "text.primary" : "text.secondary",
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          {subtitle}
        </Typography>
        <Typography variant="body1">{content}</Typography>
        {isComingSoon && (
          <Chip
            label="Coming soon"
            sx={{
              marginTop: "40px",
              color: "text.secondary",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "30px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default SpecialCard;
