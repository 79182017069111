import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Employee } from "../../core/models/employee";
import { Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../App";
import EmployeeService from "../../core/services/employeeService";
import { ProfileCardTitle } from "./profile-components/profile-card-title";
import { ProfileCardContent } from "./profile-components/profile-card-content";
import { ProfileCardUserMentee } from "./profile-components/profile-card-user-mentee";
import { ProfileCardUserMentor } from "./profile-components/profile-card-user-mentor";
import { ProfileHeader } from "./profile-components/profile-header";
import { ProfileCardContainer } from "./profile-components/profile-card-container";
import { ProfileBackground } from "./profile-components/profile-bckg";

const companyLongevityDisplay = {
  "0": "Less than a year",
  "1-3": "Between 1 and 3 years",
  "4-6": "Between 4 and 6 years",
  "6+": "More than 6 years",
};

const yearsOfExperienceDisplay = {
  "1": "Less than two years",
  "2-5": "Between 2 and 5 years",
  "6-10": "Between 6 and 10 years",
  "10+": "More than 10 years",
};

const Profile = () => {
  let location = useLocation();
  let { profileId } = useParams<"profileId">();

  const [profile, setProfile] = useState<Employee | null>();

  let { userContext } = React.useContext(UserContext);

  async function getUser() {
    const res = profileId
      ? ((await EmployeeService.getEmployeeById(profileId)) as Employee)
      : userContext?.currentUser;

    setProfile(res);
  }

  useEffect(() => {
    setProfile(null);
    getUser();
  }, [location.key]);

  return (
    <>
      {profile && (
        <Box
          sx={{
            marginTop: "70px",
            marginBottom: "100px",
          }}
        >
          <ProfileBackground />
          <Box
            sx={{
              padding: "0 120px",
              position: "relative",
              maxWidth: "1440px",
              margin: "0 auto",
              paddingTop: "10px",
            }}
          >
            <Box sx={{ marginBottom: "16px" }}>
              <ProfileHeader profile={profile} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <ProfileCardContainer>
                    <ProfileCardTitle title={"About me"} />
                    {profile?.mentoringTypesOffering.length ? (
                      <ProfileCardContent contentTitle={"Offering mentoring"}>
                        {profile?.mentoringTypesOffering?.map(
                          (mentoringType, index) => (
                            <Typography
                              key={index}
                              sx={{ color: "text.primary", fontSize: "15px" }}
                            >
                              {index === 1 ? ", " : ""}
                              {mentoringType}
                            </Typography>
                          )
                        )}
                      </ProfileCardContent>
                    ) : (
                      <Box />
                    )}
                    {profile?.mentoringTypesRequesting.length ? (
                      <ProfileCardContent contentTitle={"Seeking mentoring"}>
                        {profile?.mentoringTypesRequesting?.map(
                          (mentoringType, index) => (
                            <Typography
                              key={index}
                              sx={{ color: "text.primary", fontSize: "15px" }}
                            >
                              {index === 1 ? ", " : ""}
                              {mentoringType}
                            </Typography>
                          )
                        )}
                      </ProfileCardContent>
                    ) : (
                      <Box />
                    )}
                  </ProfileCardContainer>
                  <ProfileCardContainer>
                    <ProfileCardTitle title={"Other information"} />
                    <ProfileCardContent contentTitle={"Years in Jellyfish"}>
                      <Typography
                        sx={{ color: "text.primary", fontSize: "16px" }}
                      >
                        {companyLongevityDisplay[profile?.companyLongevity]}
                      </Typography>
                    </ProfileCardContent>
                    <ProfileCardContent contentTitle={"Years of experience"}>
                      <Typography
                        sx={{ color: "text.primary", fontSize: "16px" }}
                      >
                        {yearsOfExperienceDisplay[profile?.yearsOfExperience]}
                      </Typography>
                    </ProfileCardContent>
                    {profile?.isMentor && profile?.areasOfExpertise && (
                        <ProfileCardContent
                            contentTitle={
                              "Which of these areas do you feel well equipped to support with?"
                            }
                        >
                          <Typography
                              sx={{ color: "text.primary", fontSize: "16px" }}
                          >
                            {profile?.areasOfExpertise ? profile.areasOfExpertise.join(', ') : ''}
                          </Typography>
                        </ProfileCardContent>
                    )}
                    {profile?.isMentee && profile?.areasInNeedOfSupport && (
                        <ProfileCardContent
                            contentTitle={
                              "Which of these areas do you need support with?"
                            }
                        >
                          <Typography
                              sx={{ color: "text.primary", fontSize: "16px" }}
                          >
                            {profile?.areasInNeedOfSupport ? profile.areasInNeedOfSupport.join(', ') : ''}
                          </Typography>
                        </ProfileCardContent>
                    )}
                    <ProfileCardContent contentTitle={"Interest at Jellyfish"}>
                      <Typography
                        sx={{ color: "text.primary", fontSize: "16px" }}
                      >
                        {profile?.workInterests}
                      </Typography>
                    </ProfileCardContent>
                    <ProfileCardContent
                      contentTitle={"Non-work-related interests"}
                    >
                      <Typography
                        sx={{ color: "text.primary", fontSize: "16px" }}
                      >
                        {profile?.hobbies}
                      </Typography>
                    </ProfileCardContent>
                    {profile?.isMentor && profile?.topicsToTeach && (
                      <ProfileCardContent
                        contentTitle={
                          "What skills & topics would you like to provide mentoring on?"
                        }
                      >
                        <Typography
                          sx={{ color: "text.primary", fontSize: "16px" }}
                        >
                          {profile?.topicsToTeach}
                        </Typography>
                      </ProfileCardContent>
                    )}
                    {profile?.isMentee && profile?.topicsToLearn && (
                      <ProfileCardContent
                        contentTitle={
                          "What skills & topics would you like to be mentored on?"
                        }
                      >
                        <Typography
                          sx={{ color: "text.primary", fontSize: "16px" }}
                        >
                          {profile?.topicsToLearn}
                        </Typography>
                      </ProfileCardContent>
                    )}
                  </ProfileCardContainer>
                </Box>
                <Box>
                  {!profileId && profile?.isMentor ? (
                    <ProfileCardContainer>
                      <ProfileCardTitle title={"Pending requests"} />
                      <ProfileCardUserMentee
                        profile={profile}
                        mentoringState={"pending"}
                        warningMessage={"You don't have pending requests"}
                      />
                    </ProfileCardContainer>
                  ) : (
                    <Box />
                  )}
                  {!profileId && profile?.isMentor && (
                    <ProfileCardContainer>
                      <ProfileCardTitle title={"Current mentees"} />
                      <ProfileCardUserMentee
                        profile={profile}
                        mentoringState={"accepted"}
                        warningMessage={"You don't have mentees to mentor"}
                      />
                    </ProfileCardContainer>
                  )}
                  {!profileId && profile?.isMentee && (
                    <ProfileCardContainer>
                      <ProfileCardTitle title={"Current mentors"} />
                      <ProfileCardUserMentor profile={profile} />
                    </ProfileCardContainer>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Profile;
