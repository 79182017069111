import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { UserContext } from "../App";

const GuardedRoute = () => {
  let navigate = useNavigate();
  const [registered, setRegistered] = useState<boolean>();
  const [didFetch, setDidFetch] = useState<boolean>(false);

  let { userContext } = React.useContext(UserContext);

  useEffect(() => {
    async function getRegistered() {
      if (userContext === null) {
        return;
      }

      if (!userContext.currentUser || !userContext.currentUser.email) {
        setRegistered(false);
        setDidFetch(true);
        return;
      }

      setRegistered(true);
      setDidFetch(true);
    }

    getRegistered();

    const location: Location = JSON.parse(localStorage.getItem("location"));

    if (location?.pathname) {
      navigate(location.pathname);
      localStorage.removeItem("location");
    }
  }, [userContext]);

  return registered ? (
    <Outlet />
  ) : didFetch ? (
    <Navigate to="/register" />
  ) : (
    <div>Loading</div>
  );
};

export default GuardedRoute;
