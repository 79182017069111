import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

interface CardTitleProps {
  title: string;
}

export const ProfileCardTitle = ({ title }: CardTitleProps) => {
  return (
    <Box sx={{ padding: "16px 20px" }}>
      <Typography
        sx={{
          color: "text.primary",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
