import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const ProfileCardContent = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "16px 20px",
        borderTop: "1px solid #E0E0E0",
        width: "584px",
      }}
    >
      <Typography
        sx={{
          color: "text.primary",
          fontSize: "15px",
          fontWeight: "500",
          width: "200px",
        }}
      >
        {props.contentTitle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "30px",
          width: "310px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
