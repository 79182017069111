import React, { useState } from "react";
import { Box } from "@mui/system";
import AvatarCustom from "../../common/avatar";
import { Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { Employee, Mentee, Mentor } from "../../core/models/employee";
import { useNavigate } from "react-router-dom";
import EmployeeService from "../../core/services/employeeService";
import { UserContext } from "../../App";
import RequestModal from "../../common/modal";
import { SnackbarComponent } from "../../common/snackbar";

interface EmployeeProps {
  profile: Mentee | Mentor;
  currentUser?: Employee;
  isMentorProfileCard?: boolean;
}

const ProfileCard = ({
  profile,
  currentUser,
  isMentorProfileCard = false,
}: EmployeeProps) => {
  let navigate = useNavigate();

  const theme = useTheme();

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [isSnackBarErrorOpen, setIsSnackBarErrorOpen] = useState(false);

  const [openEndModal, setOpenEndModal] = React.useState(false);
  const handleOpenEndModal = () => setOpenEndModal(true);
  const handleCloseEndModal = () => setOpenEndModal(false);

  const [loading, setLoading] = useState(false);

  let { reloadUser } = React.useContext(UserContext);

  const goToUserProfile = () => {
    navigate(`/profile/${profile?.id}`);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
    setIsSnackBarErrorOpen(false);
    setSnackBarMessage("");
  };

  const sendRequest = async (url: string, method: string) => {
    setLoading(true);
    try {
      await EmployeeService.requestMentoring({}, url, method);
      setIsSnackBarOpen(true);
      setLoading(false);
      await reloadUser();
      navigate(`/profile`);
    } catch (e) {
      setIsSnackBarErrorOpen(true);
      setLoading(false);
    }
  };

  const ProfileRedirection = () => {
    return (
      <Box
        component="span"
        sx={{
          cursor: "pointer",
          color: theme.palette.primary.main,
          fontWeight: "500",
        }}
        onClick={goToUserProfile}
      >
        {profile?.firstName} {profile?.lastName}
      </Box>
    );
  };

  const mentoringRequestDate = new Date(
    profile?.mentoringRequestDate
  ).toDateString();

  let mentoringAcceptButton: JSX.Element | null = null;
  if (
    profile?.mentoringState === "pending" &&
    profile?.links?.mentoring_answer_accept?.href
  ) {
    mentoringAcceptButton = (
      <Button
        variant="contained"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          width: "49%",
        }}
        color="success"
        onClick={async () => {
          setSnackBarMessage(
            `You've accepted the request of ${profile?.firstName} ${profile?.lastName} 🎉`
          );
          await sendRequest(
            profile?.links?.mentoring_answer_accept?.href,
            profile?.links?.mentoring_answer_accept?.type
          );
        }}
      >
        Approve
      </Button>
    );
  }

  let mentoringDeclineButton: JSX.Element | null = null;
  if (
    profile?.mentoringState === "pending" &&
    profile?.links?.mentoring_answer_decline?.href
  ) {
    mentoringDeclineButton = (
      <Button
        variant="outlined"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          width: "49%",
        }}
        color="error"
        onClick={async () => {
          setSnackBarMessage(
            `You've declined the request of ${profile?.firstName} ${profile?.lastName}`
          );
          await sendRequest(
            profile?.links?.mentoring_answer_decline?.href,
            profile?.links?.mentoring_answer_decline?.type
          );
        }}
      >
        Decline
      </Button>
    );
  }

  let mentoringEndButton: JSX.Element | null = null;
  if (
    profile?.isMentee &&
    profile?.mentoringState === "accepted" &&
    profile?.links?.mentoring_end?.href
  ) {
    mentoringEndButton = (
      <Button
        variant="outlined"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          marginLeft: "10px",
        }}
        onClick={handleOpenEndModal}
      >
        End mentorship
      </Button>
    );
  }

  return (
    <>
      <RequestModal
        user={currentUser}
        isModalOpen={openEndModal}
        setCloseModal={handleCloseEndModal}
        requestSent={() => {}}
        userToEndRelation={profile}
        isEndModal={true}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <AvatarCustom
            name={profile?.firstName}
            imageUrl={profile?.imageUrl}
            sx={{ width: 56, height: 56 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItem: "center" }}>
              <Box
                sx={{
                  color: "text.primary",
                  fontSize: "22px",
                }}
              >
                <Box>
                  {isMentorProfileCard &&
                    profile?.mentoringState === "pending" &&
                    profile?.isMentor && (
                      <Box sx={{ fontSize: "15px" }}>
                        You requested <ProfileRedirection /> to be your mentor
                      </Box>
                    )}

                  {!isMentorProfileCard &&
                    profile?.mentoringState === "pending" &&
                    profile?.isMentee && (
                      <Box sx={{ fontSize: "15px" }}>
                        <ProfileRedirection /> requested to be your mentee
                      </Box>
                    )}

                  {profile?.mentoringState === "accepted" && (
                    <Box sx={{ fontSize: "15px" }}>
                      <ProfileRedirection />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Typography sx={{ color: "text.secondary", fontSize: "14px" }}>
              {profile?.location} . {profile?.careerLevel}
            </Typography>
            <Typography
              sx={{ color: "text.secondary", fontSize: "14px" }}
            ></Typography>
            {profile?.mentoringState === "pending" ? (
              <Typography
                sx={{ color: theme.palette.primary.main, fontSize: "14px" }}
              >
                Requested on {mentoringRequestDate}
              </Typography>
            ) : (
              <Box />
            )}
          </Box>
        </Box>
        <Box>
          {/*{profile?.links?.mentoring_end?.href && loading ? (*/}
          {/*  <CircularProgress />*/}
          {/*) : (*/}
          {/*  <Box sx={{ marginRight: "10px" }}>{mentoringEndButton}</Box>*/}
          {/*)}*/}
          {profile?.links?.mentoring_answer_accept?.href && loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop:
                  profile?.links?.mentoring_answer_accept?.href && "10px",
              }}
            >
              {mentoringDeclineButton}
              {mentoringAcceptButton}
            </Box>
          )}
        </Box>
      </Box>
      <SnackbarComponent
        type={"error"}
        isSnackBarOpen={isSnackBarErrorOpen}
        handleClose={handleClose}
        message={"Something went wrong"}
      />
      <SnackbarComponent
        type={"success"}
        isSnackBarOpen={isSnackBarOpen}
        handleClose={handleClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default ProfileCard;
