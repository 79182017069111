import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {AreaInNeedOfSupport, AreasOfExpertise, Experience, FormValue, Longevity} from "../index";
import BackBtn from "./back-btn";
import ContinueBtn from "./continue-btn";
import StepTitle from "../../../common/stepTitle";
import InputCustom from "../../../common/input";
import React from "react";

interface LongevityHelper {
  display: string;
  value: string;
}

export const companyLongevity: LongevityHelper[] = [
  { display: "< 1", value: "0" },
  { display: "1-3 years", value: "1-3" },
  { display: "4-6 years", value: "4-6" },
  { display: "6+ years", value: "6+" },
];

export const yearsOfExperience: LongevityHelper[] = [
  { display: "< 2", value: "1" },
  { display: "2-5 years", value: "2-5" },
  { display: "6-10 years", value: "6-10" },
  { display: "10+ years", value: "10+" },
];

export const areasOfExpertise: LongevityHelper[] = [
    { display: "Leading with success", value: "Leading with success" },
    { display: "Focusing on a client-centric business", value: "Focusing on a client-centric business" },
    { display: "Thriving in a global, multicultural & diverse world", value: "Thriving in a global, multicultural & diverse world" },
];

export const areasInNeedOfSupport: LongevityHelper[] = [
    { display: "Leading with success", value: "Leading with success" },
    { display: "Focusing on a client-centric business", value: "Focusing on a client-centric business" },
    { display: "Thriving in a global, multicultural & diverse world", value: "Thriving in a global, multicultural & diverse world" },
];

interface MediaFormStepThreeProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
  onSubmit: any;
  isLoading: boolean;
}

export const MediaFormStepThree = ({
  setStep,
  formValue,
  setFormValue,
  onSubmit,
  isLoading,
}: MediaFormStepThreeProps) => {
  const onClick = async () => {
    await onSubmit();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <StepTitle
        title="Can you tell us a little more about yourself?"
        subtitle="It will help us find the right network for you"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "64px",
          marginBottom: "24px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="longevity-label">
            How long have you been at Jellyfish?
          </InputLabel>
          <Select
            labelId="longevity-label"
            id="longevity"
            value={formValue?.companyLongevity}
            label="How long have you been at Jellyfish?"
            onChange={(event) => {
              const companyLongevity = event.target.value as Longevity;
              setFormValue({
                ...formValue,
                companyLongevity,
              });
            }}
          >
            {companyLongevity?.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="experience-label">
            How long have you been on your career path?
          </InputLabel>
          <Select
            labelId="experience-label"
            id="experience"
            value={formValue?.yearsOfExperience}
            label="How long have you been doing the work that you do?"
            onChange={(event) => {
              const yearsOfExperience = event.target.value as Experience;
              setFormValue({
                ...formValue,
                yearsOfExperience,
              });
            }}
          >
            {yearsOfExperience?.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formValue?.employeeType === "mentor" && (
            <FormControl fullWidth>
              <InputLabel id="areasOfExpertise-label">
                  <p>Which of these areas do you feel well equipped to support with? (Choose up to 2)</p>
                  {/*<a href="" target="_blank">Click here for more details</a>*/}
              </InputLabel>
              <Select
                  labelId="areasOfExpertise-label"
                  id="areasOfExpertise"
                  value={formValue?.areasOfExpertise}
                  multiple
                  label="Which of these areas do you feel well equipped to support with? (Choose up to 2)"
                  onChange={(event) => {
                      const areasOfExpertise = event.target.value as AreasOfExpertise[];
                      if (areasOfExpertise.length <= 2) {
                          setFormValue({
                              ...formValue,
                              areasOfExpertise: areasOfExpertise
                          });
                      }
                  }}
              >
                  {areasOfExpertise?.map((item, index) => (
                      <MenuItem value={item.value} key={item.value}>
                          {item.display}
                      </MenuItem>
                  ))}
              </Select>
            </FormControl>
        )}
        {formValue?.employeeType === "mentee" && (
          <FormControl fullWidth>
              <InputLabel id="areaInNeedOfSupport-label">
                  <p>Which of these areas do you need support with? (Choose up to 1)</p>
                  {/*<a href="" target="_blank">Click here for more details</a>*/}
              </InputLabel>
              <Select
                  labelId="areaInNeedOfSupport-label"
                  id="areaInNeedOfSupport"
                  value={formValue?.areasInNeedOfSupport}
                  multiple
                  label="Which of these areas do you feel well equipped to support with? (Choose up to 2)"
                  onChange={(event) => {
                      const areasInNeedOfSupport = event.target.value as AreaInNeedOfSupport[];
                      if (areasInNeedOfSupport.length <= 1) {
                          setFormValue({
                              ...formValue,
                              areasInNeedOfSupport: areasInNeedOfSupport
                          });
                      }
                  }}
              >
                  {areasInNeedOfSupport?.map((item, index) => (
                      <MenuItem value={item.value} key={item.value}>
                          {item.display}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>
        )}
        <InputCustom
          label="What is your particular area of interest at Jellyfish?"
          value={formValue?.workInterests}
          onChange={(event) => {
            setFormValue({
              ...formValue,
              workInterests: event.target.value,
            });
          }}
        />
        <InputCustom
          label="What are your non-work related interests?"
          value={formValue?.hobbies}
          onChange={(event) => {
            setFormValue({
              ...formValue,
              hobbies: event.target.value,
            });
          }}
        />
        {formValue?.employeeType === "mentor" && (
          <InputCustom
            label="What skills & topics would you like to provide mentoring on?"
            value={formValue?.topics}
            onChange={(event) => {
              setFormValue({
                ...formValue,
                topics: event.target.value,
              });
            }}
          />
        )}
        {formValue?.employeeType === "mentee" && (
          <InputCustom
            label="What skills & topics would you like to be mentored on?"
            value={formValue?.topics}
            onChange={(event) => {
              setFormValue({
                ...formValue,
                topics: event.target.value,
              });
            }}
          />
        )}
        <Box display="flex" justifyContent="end" alignItems="center">
          <Typography
            sx={{
              fontSize: "14px",
              color: "text.secondary",
              lineHeight: "24px",
            }}
          >
            By continuning I accept the Jellyfish{" "}
            <a href="src/pages/signup-form/steps/step-three" target="_blank">
              privacy policy
            </a>
          </Typography>
          <Checkbox
            checked={formValue.privacyChecked}
            onChange={() =>
              setFormValue({
                ...formValue,
                privacyChecked: !formValue.privacyChecked,
              })
            }
            name="Privacy policy"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                width: "201px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <BackBtn onClick={() => setStep(2)} />
              <ContinueBtn
                disabled={
                  formValue.companyLongevity === undefined ||
                  formValue.yearsOfExperience === undefined ||
                  !formValue.privacyChecked
                }
                onClick={onClick}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
