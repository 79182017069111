import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MenuProps as MenuPropsType } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface multiSelectProps {
  label: string;
  value: any;
  disabled?: boolean;
  onChange?: (event: any, value: any) => void;
  options: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps: Partial<MenuPropsType> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectCustom = ({
  label,
  value,
  options,
  onChange,
}: multiSelectProps) => {
  return (
    <Autocomplete
      sx={{
        marginLeft: "10px",
        marginTop: "10px",
        width: 250,
          '& .MuiInputBase-fullWidth': {
              borderRadius: '12px',
          },
      }}
      multiple
      id="checkboxes-tags"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      value={value}
      onChange={onChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} sx={{backgroundColor: "#fff", borderRadius: "12px"}} label={label} placeholder={label} />
      )}
    />
  );
};

// const MultiSelectCustom = ({
//   label,
//   value,
//   options,
//   onChange,
// }: multiSelectProps) => {
//   return (
//     <FormControl
//       sx={{
//         marginLeft: "10px",
//         marginTop: "10px",
//         width: "150px",
//         maxHeight: "50px",
//       }}
//     >
//       <InputLabel
//         sx={{
//           lineHeight: "1.45em",
//           top: "-4px",
//         }}
//         id="mutiple-select-label"
//       >
//         {label}
//       </InputLabel>
//       <Select
//         labelId="mutiple-select-label"
//         multiple
//         value={value}
//         onChange={onChange}
//         renderValue={(selected) => selected.join(", ")}
//         MenuProps={MenuProps}
//         sx={{
//           borderRadius: "12px",
//           border: "none",
//           height: "48px",
//           backgroundColor: "#fff",
//         }}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             <ListItemIcon>
//               <Checkbox checked={value.indexOf(option) > -1} />
//             </ListItemIcon>
//             <ListItemText primary={option} />
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

export default MultiSelectCustom;
