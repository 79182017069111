import React from "react";
import { Box } from "@mui/system";
import ProfileBckg from "../../../assets/profile-bckg.png";

export const ProfileBackground = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(${ProfileBckg})`,
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: -1,
      }}
    ></Box>
  );
};
