import { Alert, AlertColor, Snackbar } from "@mui/material";
import React from "react";

interface SnackbarProps {
  type: AlertColor;
  isSnackBarOpen: boolean;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  message: string;
}

export const SnackbarComponent = ({
  type,
  isSnackBarOpen,
  handleClose,
  message,
}: SnackbarProps) => {
  return (
    <Snackbar
      open={isSnackBarOpen}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        sx={{
          borderRadius: "64px",
        }}
        severity={type}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
