import { Alert, Box, Chip, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MediaFormStepOne } from "./steps/step-one";
import { MediaFormStepThree } from "./steps/step-three";
import { MediaFormStepTwo } from "./steps/step-two";
import RegisterService from "../../core/services/registerService";
import { UserContext } from "../../App";
import { deepPurple } from "@mui/material/colors";

export type Longevity = undefined | "1-3" | "4-6" | "6+";
export type Experience = undefined | "2-5" | "6-10" | "10+";
export type AreasOfExpertise = undefined | "Leading with success" | "Focusing on a client-centric business" | "Thriving in a global, multicultural & diverse world";
export type AreaInNeedOfSupport = undefined | "Leading with success" | "Focusing on a client-centric business" | "Thriving in a global, multicultural & diverse world";

export interface FormValue {
  employeeType: undefined | "mentee" | "mentor";
  mentoringTypes: {
    careerGuidance: boolean;
    skillSharing: boolean;
  };
  companyLongevity: Longevity;
  yearsOfExperience: Experience;
  areasOfExpertise: AreasOfExpertise[];
  areasInNeedOfSupport: AreaInNeedOfSupport[];
  workInterests: undefined | "";
  hobbies: undefined | "";
  topics: undefined | "";
  privacyChecked: boolean;
}

const menteeChipTitle = deepPurple[500];
const menteeChipBg = deepPurple[50];

export const MediaForm = () => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  const [formValue, setFormValue] = useState<FormValue>({
    employeeType: undefined,
    mentoringTypes: {
      careerGuidance: false,
      skillSharing: false,
    },
    companyLongevity: undefined,
    yearsOfExperience: undefined,
    areasOfExpertise: [],
    areasInNeedOfSupport: [],
    workInterests: undefined,
    hobbies: undefined,
    topics: undefined,
    privacyChecked: false,
  });

  let { userContext } = React.useContext(UserContext);

  const openErrorSnackbar = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const initForm = async () => {
      if (userContext === null) {
        return;
      }

      setIsUserLoaded(true);

      if (userContext.currentUser && userContext.currentUser.email) {
        // user already registered
        console.log("user already registered");
        navigate("/");
      }

      if (open) {
        setIsLoading(false);
        setStep(1);
        setFormValue({
          employeeType: undefined,
          mentoringTypes: undefined,
          companyLongevity: undefined,
          yearsOfExperience: undefined,
          areasOfExpertise: [],
          areasInNeedOfSupport: [],
          workInterests: undefined,
          hobbies: undefined,
          topics: undefined,
          privacyChecked: false,
        });
      }
    };

    initForm();
  }, [open, userContext]);

  const onSubmit = async () => {
    setIsLoading(true);

    const elementToSend = {
      employeeType: formValue.employeeType || "",
      mentoringTypes: [],
      yearsOfExperience: formValue.yearsOfExperience || "",
      companyLongevity: formValue.companyLongevity || "",
      areasOfExpertise: formValue.areasOfExpertise || [],
      areasInNeedOfSupport: formValue.areasInNeedOfSupport || [],
      workInterests: formValue.workInterests || "",
      hobbies: formValue.hobbies || "",
      topics: formValue.topics || "",
    };

    if (formValue.mentoringTypes.careerGuidance) {
      elementToSend.mentoringTypes.push("Career Guidance");
    }

    if (formValue.mentoringTypes.skillSharing) {
      elementToSend.mentoringTypes.push("Skill-sharing");
    }

    try {
      await RegisterService.register(elementToSend);
      window.location.href = "/";
    } catch (e: unknown) {
      openErrorSnackbar();
    }
  };

  const getStep = (step: number) => {
    if (step === 1) {
      return (
        <MediaFormStepOne
          setStep={setStep}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      );
    }

    if (step === 2) {
      return (
        <MediaFormStepTwo
          setStep={setStep}
          formValue={formValue}
          setFormValue={setFormValue}
        />
      );
    }

    if (step === 3) {
      return (
        <MediaFormStepThree
          setStep={setStep}
          setFormValue={setFormValue}
          formValue={formValue}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      );
    }

    console.error("Invalid step");
  };

  if (!isUserLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box sx={{ maxWidth: "1440px", margin: "0 auto" }}>
        <Box sx={{ marginTop: "70px", margin: "80px" }}>
          <Chip
            label={`Step ${step}/3`}
            sx={{
              width: "130px",
              fontWeight: "bold",
              fontSize: "16px",
              color: menteeChipTitle,
              backgroundColor: menteeChipBg,
            }}
          />
          {getStep(step)}
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            We could not compute your request. Please try again.
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
