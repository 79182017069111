import React, { useState } from "react";
import { Box } from "@mui/system";
import AvatarCustom from "../../../common/avatar";
import {
  Button,
  Chip,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { Employee } from "../../../core/models/employee";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmployeeService from "../../../core/services/employeeService";
import { UserContext } from "../../../App";
import { blue, deepPurple, indigo } from "@mui/material/colors";
import RequestModal from "../../../common/modal";
import { SnackbarComponent } from "../../../common/snackbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const menteeChipTitle = deepPurple[500];
const menteeChipBg = deepPurple[50];
const mentorChipTitle = blue[700];
const mentorChipBg = blue[50];

const selectCanMenteeRequest = (profile: Employee) => {
  return profile?.links?.mentoring_request?.href.length > 0;
};

interface ProfileHeaderProps {
  profile: Employee;
  isInEditMode?: boolean;
}

export const ProfileHeader = ({
  profile,
  isInEditMode = false,
}: ProfileHeaderProps) => {
  const { logout } = useAuth0();
  let navigate = useNavigate();
  const location = useLocation();
  let { profileId } = useParams<"profileId">();
  let { reloadUser } = React.useContext(UserContext);

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [isSnackBarErrorOpen, setIsSnackBarErrorOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const [openEndModal, setOpenEndModal] = React.useState(false);
  const handleOpenEndModal = () => setOpenEndModal(true);
  const handleCloseEndModal = () => setOpenEndModal(false);

  const [loading, setLoading] = useState(false);

  const goToEditProfile = () => {
    navigate(`/profile/edit`);
  };

  const doesIncludeEdit = (): boolean => {
    return location.pathname.includes("edit");
  };

  const goBack = () => {
    navigate(doesIncludeEdit() ? "/profile" : "/");
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackBarOpen(false);
    setIsSnackBarErrorOpen(false);
    setSnackBarMessage("");
  };

  const sendRequest = async (url: string, method: string) => {
    setLoading(true);
    try {
      await EmployeeService.requestMentoring({}, url, method);
      setIsSnackBarOpen(true);
      setLoading(false);
      await reloadUser();
      navigate(`/profile/${profile?.id}`);
    } catch (e) {
      setIsSnackBarErrorOpen(true);
      setLoading(false);
    }
  };

  const updateProfile = async () => {
    setLoading(true);
    try {
      await EmployeeService.updateEmployee(profile);
      setSnackBarMessage("Your profile has been successfully updated 🎉");
      setIsSnackBarOpen(true);
      await reloadUser();
      navigate(`/profile`);
    } catch (e) {
      setIsSnackBarErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  let mentoringAcceptButton: JSX.Element | null = null;
  if (profileId && profile?.links?.mentoring_answer_accept?.href) {
    mentoringAcceptButton = (
      <Button
        variant="contained"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          marginLeft: "10px",
          width: "49%",
        }}
        color="success"
        onClick={async () => {
          setSnackBarMessage(
            `You've accepted the request of ${profile?.firstName} ${profile?.lastName} 🎉`
          );
          await sendRequest(
            profile?.links?.mentoring_answer_accept?.href,
            profile?.links?.mentoring_answer_accept?.type
          );
        }}
      >
        Approve
      </Button>
    );
  }

  let mentoringDeclineButton: JSX.Element | null = null;
  if (profileId && profile?.links?.mentoring_answer_decline?.href) {
    mentoringDeclineButton = (
      <Button
        variant="outlined"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          marginLeft: "10px",
          width: "49%",
        }}
        color="error"
        onClick={async () => {
          setSnackBarMessage(
            `You've declined the request of ${profile?.firstName} ${profile?.lastName}`
          );
          await sendRequest(
            profile?.links?.mentoring_answer_decline?.href,
            profile?.links?.mentoring_answer_decline?.type
          );
        }}
      >
        Decline
      </Button>
    );
  }

  let mentoringEndButton: JSX.Element | null = null;
  if (profileId && profile?.isMentee && profile?.links?.mentoring_end?.href) {
    mentoringEndButton = (
      <Button
        variant="outlined"
        sx={{
          height: "40px",
          borderRadius: "54px",
          textTransform: "none",
          fontSize: "16px",
          marginLeft: "10px",
        }}
        color="error"
        onClick={handleOpenEndModal}
      >
        End mentorship
      </Button>
    );
  }

  const Modals = () => {
    return (
      <Box>
        <RequestModal
          user={profile}
          isModalOpen={open}
          setCloseModal={handleCloseModal}
          requestSent={() => {}}
        />
        <RequestModal
          isModalOpen={openEndModal}
          setCloseModal={handleCloseEndModal}
          requestSent={() => {}}
          userToEndRelation={profile}
          isEndModal={true}
        />
      </Box>
    );
  };

  return (
    <>
      <Modals />
      <Link
        component="button"
        variant="body2"
        underline="none"
        onClick={() => {
          goBack();
        }}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        <ArrowBackIcon sx={{ color: "text.primary" }} />
        <Typography
          sx={{
            marginLeft: "20px",
            color: "text.primary",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Back to {doesIncludeEdit() ? "profile" : "directory"}
        </Typography>
      </Link>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <AvatarCustom
            name={profile?.firstName}
            imageUrl={profile?.imageUrl}
            sx={{ width: 120, height: 120, margin: "10px 0" }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            {profile?.hasReachMaxMentees && (
              <Chip
                label="Fully booked"
                sx={{
                  color: menteeChipTitle,
                  backgroundColor: menteeChipBg,
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              />
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "150px",
              }}
            >
              {profile?.isMentor && (
                <Chip
                  label="Mentor"
                  sx={{
                    color: mentorChipTitle,
                    backgroundColor: mentorChipBg,
                    fontWeight: "500",
                  }}
                />
              )}
              {profile?.isMentee && (
                <Chip
                  label="Mentee"
                  sx={{
                    color: menteeChipTitle,
                    backgroundColor: menteeChipBg,
                    fontWeight: "500",
                  }}
                />
              )}
            </Box>
            <Typography sx={{ color: "text.primary", fontSize: "24px" }}>
              {profile?.firstName} {profile?.lastName}
            </Typography>
            <Typography sx={{ color: "text.primary", fontSize: "16px" }}>
              {profile?.location} . {profile?.careerLevel}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {profile?.links?.mentoring_answer_accept?.href && loading ? (
              <CircularProgress />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "400px",
                }}
              >
                {mentoringDeclineButton}
                {mentoringAcceptButton}
                {profile?.links?.mentoring_end?.href && loading ? (
                  <CircularProgress />
                ) : (
                  <Box>{mentoringEndButton}</Box>
                )}
              </Box>
            )}
            {!profileId && !isInEditMode && (
              <Button
                variant="outlined"
                onClick={goToEditProfile}
                sx={{
                  borderRadius: "54px",
                  marginLeft: "20px",
                  height: "40px",
                }}
              >
                Edit profile
              </Button>
            )}
            {selectCanMenteeRequest(profile) && (
              <Button
                variant="contained"
                onClick={handleOpenModal}
                disabled={profile?.hasReachMaxMentees}
                sx={{
                  borderRadius: "54px",
                  marginLeft: "20px",
                  height: "40px",
                  textDecoration: "uppercase",
                }}
              >
                Request mentoring
              </Button>
            )}
            {!profileId && !isInEditMode && (
              <Button
                variant="outlined"
                onClick={() => logout({ returnTo: document.location.origin })}
                sx={{
                  borderRadius: "54px",
                  marginLeft: "20px",
                  height: "40px",
                  fontWeight: "500",
                }}
                color="error"
              >
                Logout
              </Button>
            )}
            {isInEditMode && (
              <Box>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={async () => {
                      await updateProfile();
                    }}
                    variant="contained"
                    sx={{
                      borderRadius: "54px",
                      marginLeft: "20px",
                      height: "40px",
                    }}
                  >
                    Save changes
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <SnackbarComponent
        type={"error"}
        isSnackBarOpen={isSnackBarErrorOpen}
        handleClose={handleClose}
        message={"Something went wrong"}
      />
      <SnackbarComponent
        type={"success"}
        isSnackBarOpen={isSnackBarOpen}
        handleClose={handleClose}
        message={snackbarMessage}
      />
    </>
  );
};
