export class HttpService<T> {
  private token = "";
  constructor() {}

  setToken(token: string) {
    this.token = token;
  }

  async get(endpoint: string): Promise<T> {
    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data: T | null) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async post(body: any, endpoint: string, method = "POST"): Promise<T | void> {
    const params: any = {
      method,
      body: JSON.stringify(body),
    };

    params.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };

    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        if (response?.body) {
          return response.json();
        }
      })
      .then((data: T | null) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async put(body: any, endpoint: string, method = "PUT"): Promise<T | void> {
    const params: any = {
      method,
      body: JSON.stringify(body),
    };

    params.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };

    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data: T | null) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  async delete(
    body: any,
    endpoint: string,
    method = "DELETE"
  ): Promise<T | void> {
    const params: any = {
      method,
      body: JSON.stringify(body),
    };

    params.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };

    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data: T | null) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
}
