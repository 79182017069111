import { HttpService } from "./httpService";
import { Employee } from "../models/employee";

class EmployeeService {
  constructor(private httpService: HttpService<Employee | Employee[]>) {}

  setToken(token: string) {
    this.httpService.setToken(token);
  }

  async getCurrentUser() {
    return await this.httpService.get("/user").catch(() => {
      console.log("no user registered");
    });
  }

  async getEmployeeById(userId: string) {
    return await this.httpService.get(`/employee/${userId}`);
  }

  async getEmployees() {
    return await this.httpService.get("/employees");
  }

  async requestMentoring(body: any, url: string, method: string) {
    if (method === "POST") {
      return await this.httpService.post(body, url);
    } else {
      return await this.httpService.put(body, url);
    }
  }

  async updateEmployee(body: any) {
    await this.httpService.put(body, "/profile");
  }

  async mentorAnswer(body: any) {
    await this.httpService.post(body, "/mentor/answer");
  }
}

const httpService = new HttpService<Employee | Employee[]>();
const singleton = new EmployeeService(httpService);

export default singleton;
