import React from "react";
import { Box } from "@mui/system";

export const ProfileCardContainer = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "32px",
        borderRadius: "12px",
        border: "1px solid #E0E0E0",
        width: "584px",
        background: "#ffffff",
      }}
    >
      {props.children}
    </Box>
  );
};
