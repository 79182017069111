import {User} from '../models/user';
import {HttpService} from './httpService';

class UserService {

    constructor(private httpService: HttpService<User>) {
    }

    setToken(token: string) {
        this.httpService.setToken(token);
    }

    async getCurrentUser(): Promise<User | void> {
        return await this.httpService.get("/user")
            .catch(() => {
                console.log('no user registered');
            });
    }
}

const httpService = new HttpService<User>();
const service = new UserService(httpService);

export default service;
