import { Button } from "@mui/material";

interface ContinueBtnProps {
  disabled?: boolean;
  onClick: () => void;
}

const ContinueBtn = ({ disabled, onClick }: ContinueBtnProps) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "space-center",
        width: "110px",
        height: "38px",
        fontWeight: "bold",
        borderRadius: "54px",
      }}
    >
      Continue
    </Button>
  );
};

export default ContinueBtn;
