import React from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import AvatarCustom from "../../../common/avatar";
import { useNavigate } from "react-router-dom";
import { indigo, deepPurple, blue, lightBlue } from "@mui/material/colors";
import RequestModal from "../../../common/modal";
import { Employee } from "../../../core/models/employee";

const menteeChipTitle = deepPurple[500];
const menteeChipBg = deepPurple[50];
const mentorChipTitle = blue[700];
const mentorChipBg = blue[50];

interface EmployeeCardProps {
  employee: Employee;
  refreshDirectory: () => void;
}

const EmployeeCard = ({ employee, refreshDirectory }: EmployeeCardProps) => {
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const goToUserProfile = () => {
    navigate(`/profile/${employee?.id}`);
  };

  const requestSent = () => {
    refreshDirectory();
  };

  return (
    <>
      <Card
        sx={{
          width: 293,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "24px",
          boxShadow: "0px 0px 0px 1px #E0E0E0",
          backgroundColor: "#FFF",
          cursor: "pointer",
        }}
        onClick={goToUserProfile}
      >
        <CardActionArea>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "16px",
            }}
          >
            <AvatarCustom
              name={employee.firstName}
              imageUrl={employee.imageUrl}
              sx={{
                width: 72,
                height: 72,
                borderRadius: "10px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "65%",
              }}
            >
              {employee.isMentor && (
                <Chip
                  label="Mentor"
                  sx={{
                    color: mentorChipTitle,
                    backgroundColor: mentorChipBg,
                    marginRight: "10px",
                    fontWeight: "500",
                  }}
                />
              )}
              {employee.isMentee && (
                <Chip
                  label="Mentee"
                  sx={{
                    color: menteeChipTitle,
                    backgroundColor: menteeChipBg,
                    marginRight: "10px",
                    fontWeight: "500",
                  }}
                />
              )}
            </Box>
          </Box>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "0 16px 16px 16px",
              "&:last-child": { pb: "16px" },
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                color: "text.primary",
                marginBottom: 0,
              }}
            >
              {employee?.firstName} {employee?.lastName}
            </Typography>
            <Typography
              gutterBottom
              component="p"
              sx={{
                fontSize: "12px",
                textAlign: "left",
                color: "text.secondary",
                marginBottom: 0,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {employee.location} . {employee.careerLevel}
            </Typography>
            {false && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "30px",
                    borderRadius: "54px",
                    textTransform: "none",
                    width: employee?.links?.mentoring_request?.href
                      ? "48%"
                      : "100%",
                  }}
                  onClick={goToUserProfile}
                >
                  View profile
                </Button>
                {employee?.links?.mentoring_request?.href && (
                  <Button
                    variant="contained"
                    sx={{
                      height: "30px",
                      borderRadius: "54px",
                      textTransform: "none",
                      width: "48%",
                    }}
                    onClick={handleOpenModal}
                  >
                    Request
                  </Button>
                )}
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      <RequestModal
        user={employee}
        isModalOpen={open}
        setCloseModal={handleCloseModal}
        requestSent={requestSent}
      />
    </>
  );
};

export default EmployeeCard;
