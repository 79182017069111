import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useAuth0 } from "@auth0/auth0-react";
import { Background } from "../../common/background";
import LoginBackground from "../../assets/login-bckg-1.png";
import LogoJellyfishMentoring from "../../assets/logo-mentoring.png";
import Connect from "../../assets/connect.svg";
import GiveBack from "../../assets/give-back.svg";
import Expand from "../../assets/expand.svg";
import { useLocation } from "react-router-dom";

const LoginCard = ({ title, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <img src={icon} alt={icon} />
      </Box>
      <Typography
        sx={{ color: "#523FF6", fontWeight: "500", fontSize: "13px" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const Login = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem(
      "location",
      JSON.stringify(location.pathname.includes("profile") ? location : {})
    );
  }, []);

  const { loginWithRedirect } = useAuth0();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Background backgroundImage={LoginBackground} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "50%",
          height: "100vh",
        }}
      >
        <Box sx={{ height: "335px", width: "450px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffffff",
              height: "75%",
              border: "12px",
              borderTopRightRadius: "12px",
              borderTopLeftRadius: "12px",
            }}
          >
            <img
              className={"jellyfish-logo"}
              src={LogoJellyfishMentoring}
              alt={"Logo"}
            />
            <Button
              variant="outlined"
              sx={{
                borderRadius: "54px",
                marginTop: "40px",
                width: "280px",
                height: "50px",
                fontSize: "16px",
                color: "text.primary",
                border: "1px solid",
              }}
              startIcon={<GoogleIcon />}
              onClick={() => loginWithRedirect()}
            >
              Sign in with Google
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              backgroundColor: "#e9e5ff",
              height: "25%",
              borderBottomRightRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}
          >
            <LoginCard title={"Connect"} icon={Connect} />
            <LoginCard title={"Give back"} icon={GiveBack} />
            <LoginCard title={"Expand"} icon={Expand} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
