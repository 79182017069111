import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarCustom from "./avatar";
import { UserContext } from "../App";
import { useTheme } from "@mui/material";

const Navbar = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const location = useLocation();

  const isProfilePage = () => {
    return location.pathname.includes("/profile");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToHome = () => {
    navigate("/");
  };

  const { userContext } = React.useContext(UserContext);

  return (
    <Box
      sx={{
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: "1px solid #E0E0E0",
        backgroundColor: "#ffffff",
        position: "fixed",
        top: 0,
        zIndex: "99",
      }}
    >
      <Box
        sx={{ marginLeft: "50px", width: "100px", cursor: "pointer" }}
        onClick={() => goToHome()}
      >
        <img
          style={{ width: "180px" }}
          src={require("../assets/logo-mentoring.png")}
          alt="Logo"
        />
      </Box>
      <Box
        sx={{
          marginRight: "50px",
          cursor: "pointer",
        }}
        onClick={() => goToProfile()}
      >
        <AvatarCustom
          name={userContext?.currentUser?.firstName}
          imageUrl={userContext?.currentUser?.imageUrl}
          sx={{
            width: 40,
            height: 40,
            "&:hover": {
              outline: `2.5px solid ${theme.palette.primary.main}`,
            },
            outline: `${
              isProfilePage() ? `2.5px solid ${theme.palette.primary.main}` : ""
            }`,
          }}
        />
      </Box>
    </Box>
  );
};

export default Navbar;
