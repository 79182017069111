import React from "react";
import { TextField } from "@mui/material";

interface InputProps {
  label: string;
  value: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const InputCustom = ({ label, value, disabled, onChange }: InputProps) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      disabled={disabled}
      onChange={onChange}
      className={"inputRounded"}
      sx={{ width: "100%" }}
      data-testid="inputCustom"
    />
  );
};

export default InputCustom;
