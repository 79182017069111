import { Box, InputBase, Paper } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MultiSelectCustom from "../../../common/multiSelect";

const EmployeeCardsFilters = (props) => {
  const [locationsFilter, setLocationsFilter] = useState([]);
  const [careerLevelsFilter, setCareerLevelsFilter] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  enum EmployeeType {
    MENTOR = "Mentor",
    MENTEE = "Mentee",
  }

  const userRoles: any = [EmployeeType.MENTOR, EmployeeType.MENTEE];

  const handleChangeLocation = (_event, value) => {
    setLocationsFilter(typeof value === "string" ? value.split(",") : value);
    props.setLocation(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeCareerLevel = (_event, value) => {
    setCareerLevelsFilter(typeof value === "string" ? value.split(",") : value);
    props.setCareerLevel(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeRole = (_event, value) => {
    setSelectedRoles(value);

    if (value) {
      props.setIsMentee(value.includes(EmployeeType.MENTOR));
      props.setIsMentor(value.includes(EmployeeType.MENTEE));
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F6F8FA",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          height: "56px",
            borderRadius: "12px",
          margin: "10px 0 10px 0",
          boxShadow: "none",
          border: "1px solid #bfbfbf",
          width: "150px",
          backgroundColor: "#fff",
        }}
      >
        <SearchIcon
          sx={{
            marginLeft: "10px",
            fontSize: "23px",
          }}
        />
        <InputBase
          fullWidth
          sx={{ border: "none", marginLeft: "5px", width: "150px" }}
          placeholder="Search..."
          onChange={(event) => props.setValue(event.target.value)}
        />
      </Paper>

      <MultiSelectCustom
        label="Location"
        value={locationsFilter}
        options={props.locations}
        onChange={handleChangeLocation}
      />
      <MultiSelectCustom
        label="Current role"
        value={careerLevelsFilter}
        options={props.careerLevels}
        onChange={handleChangeCareerLevel}
      />
      <MultiSelectCustom
        label="Mentoring role"
        value={selectedRoles}
        options={userRoles}
        onChange={handleChangeRole}
      />
    </Box>
  );
};

export default EmployeeCardsFilters;
