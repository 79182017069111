import React, { useEffect, useState } from "react";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import {
  CircularProgress,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { MediaForm } from "./pages/signup-form";
import GuardedRoute from "./authGuard/guardedRoute";
import ProfileEdit from "./pages/profile/profile-edit";
import Profile from "./pages/profile/profile";
import { Explore } from "./pages/directory/explore/explore";
import { Box } from "@mui/system";
import Navbar from "./common/navbar";
import EmployeeService from "./core/services/employeeService";
import RegisterService from "./core/services/registerService";
import { User } from "./core/models/user";
import UserService from "./core/services/userService";
import Login from "./pages/login/login";

let theme = createTheme({
  palette: {
    primary: {
      main: "#523FF6",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["DM Sans"].join(","),
    h1: {
      fontSize: "48px",
      fontWeight: "500",
      fontFamily: ["DM Sans"].join(","),
    },
    body1: {
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: ["DM Sans"].join(","),
    },
    body2: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#808080",
      fontFamily: ["DM Sans"].join(","),
    },
  },
});

interface UserWithToken {
  currentUser: User | null;
  token: string;
}

interface UserContext {
  userContext: UserWithToken;
  reloadUser: () => Promise<void>;
}

export const UserContext = React.createContext<UserContext>({
  userContext: {
    currentUser: {
      companyLongevity: "",
      email: "",
      firstName: "",
      id: "",
      isMentee: false,
      isMentor: false,
      lastName: "",
      location: "",
      yearsOfExperience: "",
      hasMentor: false,
      hasReachMaxMentees: false,
      mentees: [],
      mentors: [],
    },
    token: "",
  },
  reloadUser: () => void {},
});

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [userContext, setUserContext] = useState<UserWithToken | null>(null);

  const [userLoading, setUserLoading] = useState(false);

  const getUser = async () => {
    setUserLoading(true);
    try {
      const token = await getAccessTokenSilently({});
      UserService.setToken(token);
      await UserService.getCurrentUser()
        .then((response) => {
          setUserContext({
            currentUser: response as User,
            token: token,
          });
        })
        .catch(() => {
          setUserContext({
            currentUser: null,
            token: token,
          });
        });
    } catch (e) {
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const setAuth0TokenToHttpService = async () => {
      const token = await getAccessTokenSilently({});
      EmployeeService.setToken(token);
      RegisterService.setToken(token);
      return token;
    };

    setAuth0TokenToHttpService();

    if (isAuthenticated) {
      getUser();
      return;
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          margin: "0 auto",
          marginTop: "100px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={theme}>
        <Login />
      </ThemeProvider>
    );
  }

  const reloadUser = async () => {
    await getUser();
  };

  const render = () => {
    return (
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <UserContext.Provider value={{ userContext, reloadUser }}>
          <ThemeProvider theme={theme}>
            <Navbar />
            {userLoading ? (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  margin: "0 auto",
                  marginTop: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  margin: "0 auto",
                }}
              >
                <Routes>
                  <Route path="/" element={<GuardedRoute />}>
                    <Route path="/" element={<Explore />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/edit" element={<ProfileEdit />} />
                    <Route path="/profile/:profileId" element={<Profile />} />
                  </Route>
                  <Route path="/register" element={<MediaForm />} />
                  <Route path="/login" element={<Login />} />
                </Routes>
              </Box>
            )}
            <Box
              sx={{
                margin: "0 auto",
                padding: "15px 0",
                borderTop: "1px solid #E0E0E0",
                position: "fixed",
                width: "100%",
                bottom: 0,
                backgroundColor: "#ffffff",
              }}
            >
              <Typography
                variant="body2"
                sx={{ textAlign: "center", fontSize: "10px" }}
              >
                © 2022 Jellyfish all rights reserved
              </Typography>
            </Box>
          </ThemeProvider>
        </UserContext.Provider>
      </Box>
    );
  };

  return render();
}

export default App;
