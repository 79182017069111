import React from "react";
import { Box } from "@mui/system";
import ProfileCard from "../ProfileCard";
import { Employee } from "../../../core/models/employee";

interface CardUserProps {
  profile: Employee;
  mentoringState: string;
  warningMessage: string;
}

export const ProfileCardUserMentee = ({
  profile,
  mentoringState,
  warningMessage,
}: CardUserProps) => {
  return (
    <>
      {profile?.mentees !== undefined && (
        <Box>
          {profile?.mentees.filter(
            (mentee) => mentee.mentoringState === mentoringState
          ).length ? (
            <Box>
              {profile?.mentees
                .filter((mentee) => mentee.mentoringState === mentoringState)
                .map((mentee, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "16px 20px",
                      borderTop: "1px solid #E0E0E0",
                    }}
                  >
                    <ProfileCard profile={mentee} />
                  </Box>
                ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "16px 20px",
                borderTop: "1px solid #E0E0E0",
                color: "text.secondary",
                fontSize: "12px",
              }}
            >
              {warningMessage}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
