import { Box } from "@mui/material";
import { FormValue } from "../index";
import BackBtn from "./back-btn";
import ContinueBtn from "./continue-btn";
import SpecialCard from "../../../common/specialCard";
import StepTitle from "../../../common/stepTitle";

interface MediaFormStepOneProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
}

export const MediaFormStepTwo = ({
  setStep,
  formValue,
  setFormValue,
}: MediaFormStepOneProps) => {
  console.log(formValue);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <StepTitle
        title={
          formValue?.employeeType === "mentee"
            ? "How do you want to be helped ?"
            : "How are you willing to help?"
        }
        subtitle="You may choose either one or both"
      />
      <Box
        sx={{
          display: "flex",
          gap: "60px",
          marginTop: "64px",
          marginBottom: "24px",
        }}
      >
        <SpecialCard
          isSelected={formValue?.mentoringTypes?.careerGuidance}
          onClick={() => {
            setFormValue({
              ...formValue,
              mentoringTypes: {
                ...formValue?.mentoringTypes,
                careerGuidance: !formValue?.mentoringTypes?.careerGuidance,
              },
            });
          }}
          title="Career guidance"
          subtitle=""
          content={
            formValue?.employeeType === "mentor"
              ? "Help other Jellies in their career, personal development or skills improvement."
              : "Find a mentor to help you in your career, personal development or skills improvement."
          }
        />
        <SpecialCard
          isSelected={formValue.mentoringTypes?.skillSharing}
          onClick={() => {
            setFormValue({
              ...formValue,
              mentoringTypes: {
                ...formValue.mentoringTypes,
                skillSharing: !formValue.mentoringTypes?.skillSharing,
              },
            });
          }}
          title="Skills sharing"
          subtitle=""
          content={
            formValue?.employeeType === "mentor"
              ? "Help other Jellies in their personal development by sharing your experiences and skills."
              : "Find a mentor to help you by sharing their experiences and skills."
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <BackBtn onClick={() => setStep(1)} />
        <ContinueBtn
          disabled={
            !formValue.mentoringTypes?.careerGuidance &&
            !formValue.mentoringTypes?.skillSharing
          }
          onClick={() => setStep(3)}
        />
      </Box>
    </Box>
  );
};
