import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Employee } from "../../core/models/employee";
import { Box } from "@mui/system";
import InputCustom from "../../common/input";
import { UserContext } from "../../App";
import { User } from "../../core/models/user";
import { ProfileCardTitle } from "./profile-components/profile-card-title";
import { ProfileHeader } from "./profile-components/profile-header";
import { ProfileBackground } from "./profile-components/profile-bckg";
import { ProfileCardContainer } from "./profile-components/profile-card-container";
import { SnackbarComponent } from "../../common/snackbar";

const ProfileEdit = () => {
  const [profile, setProfile] = useState<Employee | User | null>();
  const [profileEdited, setProfileEdited] = useState<Employee | User | null>();

  const [companyLongevity, setCompanyLongevity] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [areasOfExpertise, setAreasOfExpertise] = useState([]);
  const [areasInNeedOfSupport, setAreasInNeedOfSupport] = useState([]);
  const [isMentor, setIsMentor] = useState(false);
  const [isMentee, setIsMentee] = useState(false);

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [isSnackBarErrorOpen, setIsSnackBarErrorOpen] = useState(false);

  let { userContext } = React.useContext(UserContext);

  useEffect(() => {
    setProfileEdited(null);
    setCompanyLongevity("");
    setYearsOfExperience("");
    setAreasOfExpertise([]);
    setAreasInNeedOfSupport([]);
    setIsMentor(false);
    setIsMentee(false);

    async function getUser() {
      const res = userContext?.currentUser;

      if (res) {
        setProfile(res);
        setProfileEdited(res);
        setCompanyLongevity(res.companyLongevity);
        setYearsOfExperience(res.yearsOfExperience);
        setAreasOfExpertise(res.areasOfExpertise);
        setAreasInNeedOfSupport(res.areasInNeedOfSupport);
        setIsMentor(res?.isMentor);
        setIsMentee(res?.isMentee);
      }
    }

    getUser();
  }, [location]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackBarOpen(false);
    setIsSnackBarErrorOpen(false);
    setSnackBarMessage("");
  };

  return (
    <Box sx={{ marginTop: "70px", marginBottom: "100px" }}>
      <ProfileBackground />
      <Box
        sx={{
          padding: "0 120px",
          maxWidth: "1440px",
          margin: "0 auto",
          paddingTop: "10px",
        }}
      >
        {profile && profileEdited ? (
          <Box>
            <ProfileHeader profile={profileEdited} isInEditMode={true} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <ProfileCardContainer>
                <ProfileCardTitle title={"About me"} />
                <Box sx={{ padding: "16px", borderTop: "1px solid #E0E0E0" }}>
                  <InputCustom
                    label="Full name"
                    value={`${profileEdited?.firstName} ${profileEdited?.lastName}`}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="Job title"
                    value={profileEdited?.careerLevel}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="Location"
                    value={profileEdited?.location}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="Capability partner"
                    value={profileEdited?.capabilityPartner}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="Offering mentoring"
                    value={profileEdited?.mentoringTypesOffering.join()}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="Seeking offering"
                    value={profileEdited?.mentoringTypesRequesting.join()}
                    disabled={true}
                  />
                </Box>
              </ProfileCardContainer>
              <ProfileCardContainer>
                <ProfileCardTitle title={"Other information"} />
                <Box sx={{ padding: "16px", borderTop: "1px solid #E0E0E0" }}>
                  <FormControl fullWidth className={"inputRounded"}>
                    <InputLabel id="demo-simple-select-label">
                      How long have you been at Jellyfish?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={companyLongevity}
                      label="How long have you been at Jellyfish?"
                      defaultValue={profileEdited?.companyLongevity}
                      onChange={(event) => {
                        setCompanyLongevity(event.target.value as string);
                        setProfileEdited({
                          ...profileEdited,
                          companyLongevity: event.target.value,
                        });
                      }}
                    >
                      <MenuItem value={"0"}>Less than a year</MenuItem>
                      <MenuItem value={"1-3"}>Between 1 and 3 years</MenuItem>
                      <MenuItem value={"4-6"}>Between 4 and 6 years</MenuItem>
                      <MenuItem value={"6+"}>More than 6 years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <FormControl fullWidth className={"inputRounded"}>
                    <InputLabel id="demo-simple-select-label">
                      How long have you been on your career path?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={yearsOfExperience}
                      label="How long have you been on your career path?"
                      defaultValue={profileEdited?.yearsOfExperience}
                      onChange={(event) => {
                        setYearsOfExperience(event.target.value as string);
                        setProfileEdited({
                          ...profileEdited,
                          yearsOfExperience: event.target.value,
                        });
                      }}
                    >
                      <MenuItem value={"1"}>Less than two years</MenuItem>
                      <MenuItem value={"2-5"}>Between 2 and 5 years</MenuItem>
                      <MenuItem value={"6-10"}>Between 6 and 10 years</MenuItem>
                      <MenuItem value={"10+"}>More than 10 years</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {isMentor && (
                  <Box sx={{ padding: "16px" }}>
                    <FormControl fullWidth className={"inputRounded"}>
                      <InputLabel id="demo-simple-select-label">
                        Which of these areas do you feel well equipped to support with? (Choose up to 2)
                      </InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={areasOfExpertise}
                          label="Which of these areas do you feel well equipped to support with? (Choose up to 2)"
                          defaultValue={profileEdited?.areasOfExpertise}
                          onChange={(event) => {
                            if (event.target.value.length <= 2) {
                              setAreasOfExpertise(event.target.value as string[]);
                              setProfileEdited({
                                ...profileEdited,
                                areasOfExpertise: event.target.value as string[],
                              });
                            }
                          }}
                          multiple
                      >
                        <MenuItem value={"Leading with success"}>Leading with success</MenuItem>
                        <MenuItem value={"Focusing on a client-centric business"}>Focusing on a client-centric business</MenuItem>
                        <MenuItem value={"Thriving in a global, multicultural & diverse world"}>Thriving in a global, multicultural & diverse world</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {isMentee && (
                    <Box sx={{ padding: "16px" }}>
                      <FormControl fullWidth className={"inputRounded"}>
                        <InputLabel id="demo-simple-select-label">
                          Which of these areas do you need support with? (Choose up to 1)
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={areasInNeedOfSupport}
                            label="Which of these areas do you need support with? (Choose up to 1)"
                            defaultValue={profileEdited?.areasInNeedOfSupport}
                            onChange={(event) => {
                              if (event.target.value.length <= 1) {
                                setAreasInNeedOfSupport(event.target.value as string[]);
                                setProfileEdited({
                                  ...profileEdited,
                                  areasInNeedOfSupport: event.target.value as string[],
                                });
                              }
                            }}
                            multiple
                        >
                          <MenuItem value={"Leading with success"}>Leading with success</MenuItem>
                          <MenuItem value={"Focusing on a client-centric business"}>Focusing on a client-centric business</MenuItem>
                          <MenuItem value={"Thriving in a global, multicultural & diverse world"}>Thriving in a global, multicultural & diverse world</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                )}
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="What is your particular area of interest at Jellyfish?"
                    value={profileEdited?.workInterests}
                    onChange={(event) => {
                      setProfileEdited({
                        ...profileEdited,
                        workInterests: event.target.value,
                      });
                    }}
                  />
                </Box>
                <Box sx={{ padding: "16px" }}>
                  <InputCustom
                    label="What are your non-work-related interests?"
                    value={profileEdited?.hobbies}
                    onChange={(event) => {
                      setProfileEdited({
                        ...profileEdited,
                        hobbies: event.target.value,
                      });
                    }}
                  />
                </Box>
                {isMentor && (
                  <Box sx={{ padding: "16px" }}>
                    <InputCustom
                      label="What skills & topics would you like to provide mentoring on?"
                      value={profileEdited?.topicsToTeach}
                      onChange={(event) => {
                        setProfileEdited({
                          ...profileEdited,
                          topicsToTeach: event.target.value,
                        });
                      }}
                    />
                  </Box>
                )}
                {isMentee && (
                  <Box sx={{ padding: "16px" }}>
                    <InputCustom
                      label="What skills & topics would you like to be mentored on?"
                      value={profileEdited?.topicsToLearn}
                      onChange={(event) => {
                        setProfileEdited({
                          ...profileEdited,
                          topicsToLearn: event.target.value,
                        });
                      }}
                    />
                  </Box>
                )}
              </ProfileCardContainer>
            </Box>
            <ProfileCardContainer>
              <ProfileCardTitle title={"Mentor setting"} />
              <Box sx={{ padding: "16px", borderTop: "1px solid #E0E0E0" }}>
                <Typography
                  sx={{
                    color: "text.primary",
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  Your Role
                </Typography>
                <FormGroup sx={{ marginTop: "10px" }}>
                  <Grid container direction="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMentor}
                          onChange={(event) => {
                            setIsMentor(event.target.checked);
                            setProfileEdited({
                              ...profileEdited,
                              isMentor: event.target.checked,
                            });
                          }}
                        />
                      }
                      label="Mentor"
                    />
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox checked={profileEdited?.isMentee ?? false} />
                      }
                      label="Mentee"
                    />
                  </Grid>
                </FormGroup>
              </Box>
            </ProfileCardContainer>
          </Box>
        ) : (
          <div>No user to display</div>
        )}
      </Box>
      <SnackbarComponent
        type={"error"}
        isSnackBarOpen={isSnackBarErrorOpen}
        handleClose={handleClose}
        message={"Something went wrong"}
      />
      <SnackbarComponent
        type={"success"}
        isSnackBarOpen={isSnackBarOpen}
        handleClose={handleClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ProfileEdit;
