import { Box } from "@mui/system";

export const Background = ({ backgroundImage }) => {
  return (
    <Box
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: -1,
      }}
    ></Box>
  );
};
