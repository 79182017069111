import { Box } from "@mui/material";
import { FormValue } from "../index";
import ContinueBtn from "./continue-btn";
import SpecialCard from "../../../common/specialCard";
import StepTitle from "../../../common/stepTitle";

interface MediaFormStepOneProps {
  setStep: any;
  formValue: FormValue;
  setFormValue: React.Dispatch<React.SetStateAction<FormValue>>;
}

export const MediaFormStepOne = ({
  setStep,
  formValue,
  setFormValue,
}: MediaFormStepOneProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <StepTitle
        title="What is your role?"
        subtitle="You’ll be able to extend your profile later."
      />
      <Box
        sx={{
          display: "flex",
          gap: "60px",
          marginTop: "64px",
          marginBottom: "24px",
        }}
      >
        <SpecialCard
          isSelected={formValue.employeeType === "mentee"}
          onClick={() => {
            setFormValue({ ...formValue, employeeType: "mentee" });
          }}
          title="A mentee 👩‍🎓"
          subtitle=""
          content="Find a mentor to help you in your career, personal development or skills improvement"
        />
        <SpecialCard
          isSelected={formValue.employeeType === "mentor"}
          onClick={() => setFormValue({ ...formValue, employeeType: "mentor" })}
          title="A mentor 👩🏽‍🏫"
          subtitle=""
          content="Help other Jellies in their personal development by sharing your experience and skills."
        />
        <SpecialCard
          isSelected={false}
          title="Extra curricular 🎸 🏓"
          subtitle=""
          content="Teach guitar lessons, look for an English conversation partner, find a Jelly to play tennis with in your local City"
          isComingSoon
          isDisabled
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <ContinueBtn
          disabled={formValue.employeeType === undefined}
          onClick={() => setStep(2)}
        />
      </Box>
    </Box>
  );
};
