import { Alert, CircularProgress, Snackbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import EmployeeCard from "../employee-card/employee-card";
import EmployeeCardsFilters from "../employee-cards-filters/employee-cards-filters";
import EmployeeService from "../../../core/services/employeeService";
import { UserContext } from "../../../App";
import { useLocation } from "react-router-dom";
import { Employee } from "../../../core/models/employee";

const ExploreDirectory = () => {
  let location = useLocation();

  const [employees, setEmployees] = useState<Employee[] | null>([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [locationFilter, setLocationFilter] = useState([]);
  const [locations, setLocations] = useState([]);
  const [careerLevelFilter, setCareerLevelFilter] = useState([]);
  const [careerLevels, setCareerLevels] = useState([]);
  const [isMentee, setIsMentee] = useState(false);
  const [isMentor, setIsMentor] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isSnackBarErrorOpen, setIsSnackBarErrorOpen] = useState(false);

  let { userContext } = React.useContext(UserContext);

  async function getEmployees(): Promise<void> {
    setLoading(true);
    try {
      const employees = (await EmployeeService.getEmployees()) as Employee[];

      if (employees) {
        setEmployees(employees);
        setLocations([
          ...new Set(
            employees
              ?.filter((employee) => employee.location)
              .map((employee) => employee.location)
          ),
        ]);
        setCareerLevels([
          ...new Set(
            employees
              ?.filter((employee) => employee.careerLevel)
              .map((employee) => employee.careerLevel)
          ),
        ]);
      }
    } catch (e) {
      setIsSnackBarErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEmployees();
  }, [location]);

  const setValue = (value) => {
    setSearchBarValue(value);
  };

  const setValueLocation = (value) => {
    setLocationFilter(value);
  };

  const setValueCareerLevel = (value) => {
    setCareerLevelFilter(value);
  };

  const setIsMenteeFilter = (value) => {
    setIsMentee(value);
  };

  const setIsMentorFilter = (value) => {
    setIsMentor(value);
  };

  const refreshDirectory = async () => {
    await getEmployees();
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackBarErrorOpen(false);
  };

  const filteredEmployees = () =>
    employees
      ?.filter((employee) => employee.id !== userContext?.currentUser?.id)
      .filter((employee) => {
        const attributesToSearch = [
          "firstName",
          "lastName",
          "careerLevel",
          "location",
          "topicsToLearn",
          "topicsToTeach",
          "hobbies",
          "workInterests",
        ];

        const searchValue = searchBarValue.toLowerCase().trim();
        //loop through all attributes and check if any of them contain the search value
        for (let i = 0; i < attributesToSearch.length; i++) {
          const attribute = attributesToSearch[i];
          if (
            employee[attribute] &&
            employee[attribute].toLowerCase().includes(searchValue)
          ) {
            return true;
          }
        }

        return false;
      })
      .filter((employee) =>
        careerLevelFilter?.length
          ? careerLevelFilter?.includes(employee?.careerLevel)
          : employee
      )
      .filter((employee) =>
        locationFilter.length
          ? locationFilter?.includes(employee?.location)
          : employee
      )
      .filter((employee) => (isMentor ? employee.isMentee : employee))
      .filter((employee) => (isMentee ? employee.isMentor : employee));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "calc(100% - 120px)",
        marginTop: "70px",
        backgroundColor: "#F6F8FA",
      }}
    >
      <Box
        sx={{
          padding: "30px 100px 20px 100px",
          height: "calc(100vh - 115px)",
          overflow: "scroll",
          overflowX: "hidden",
          width: "1440px",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#F6F8FA",
            marginBottom: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "text.primary",
              margin: "auto 0",

            }}
          >
            Directory of Jellies
          </Typography>
          <Box
            sx={{
              overflowY: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <EmployeeCardsFilters
              value={searchBarValue}
              setValue={setValue}
              setLocation={setValueLocation}
              setCareerLevel={setValueCareerLevel}
              setIsMentee={setIsMenteeFilter}
              setIsMentor={setIsMentorFilter}
              locations={locations}
              careerLevels={careerLevels}
            />
          </Box>
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {filteredEmployees().length ? (
              filteredEmployees().map((employee, index) => (
                <Grid xs={2} sm={3} md={3} key={index}>
                  <EmployeeCard
                    employee={employee}
                    refreshDirectory={refreshDirectory}
                  />
                </Grid>
              ))
            ) : (
              <Box sx={{ marginLeft: "15px" }}>
                There is no user corresponding to your research
              </Box>
            )}
          </Grid>
        )}
      </Box>

      <Snackbar
        open={isSnackBarErrorOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ zIndex: "99999999", borderRadius: "64px" }}
      >
        <Alert severity="error">Something went wrong</Alert>
      </Snackbar>
    </Box>
  );
};

export default ExploreDirectory;
